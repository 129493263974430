@import '../common.scss';

.telephony {
    background-color: #fcfcfc;
    iframe {
        border: none;
    }

    .mf-phone-wrapper {
        height: 666px;
        width: 376px;
    }
    .telephony-large-modal {
        // .mf-actions {
        //     display: none;
        // }

        // .mf-header {
        //     // display: none;
        //     // .mf-header-icon {
        //     //     &:hover {
        //     //         display: none;
        //     //     }
        //     // }
        //     // height: 20px;
        //     // background-color: greenyellow;
        //     &:hover {
        //         // .mf-actions {
        //         //     display: contents;
        //         // }
        //         // .mf-header-icon {
        //         //     display: none;
        //         // }
        //     }
        // }
        &:hover {
            cursor: grab;
            // .mf-header {
            //     display: contents;
            // }
        }
        background-color: #fcfcfc;
        position: fixed;

        top: 10%;
        right: 10%;

        margin: 0 auto 0 auto;
        z-index: 9999;

        border-radius: 5px;
        box-shadow: 0px 0px 10px rgba(#fff, 0.5), 0px 0px 5px rgba(#000, 0.5);
        padding: 0px 10px 5px 10px;
    }
}

.mf-call-number__icon,
.mf-call-number {
    background-color: $mf-light-purple;
    &:hover {
        cursor: pointer;
        background-color: $mf-brand;
        color: white !important;
    }
}

.mf-call-number {
    border-radius: 10px;
    // color: white !important;

    padding-left: 10px;
    position: relative;
    left: -10px;
}

.mf-call-number__icon {
    border-radius: 10px;
    position: relative;
    padding: 5px;
    left: -5px;
}
