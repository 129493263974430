@import '../common.scss';

// .fc-unthemed {
//     .fc-toolbar {
//         .fc-button {
//             color: black;
//         }
//     }

//     .fc-button-primary:not(:disabled):active,
//     .fc-button-primary:not(:disabled).fc-button-active {
//         background-color: $mf-brand;
//         color: white;
//     }
// }

.mf-fc-custom.fc {
    // .fc-timegrid-event-condensed .fc-event-main-frame {
    //     flex-direction: row-reverse;
    // }
    @media (max-width: 991.98px) {
        .fc-toolbar {
            // display: block;
            flex-direction: column;
        }

        .fc-view-harness {
            &.fc-view-harness-active {
                height: calc(100vh - 220px);
            }
        }
    }

    .fc-timegrid-event-condensed .fc-event-title {
        font-size: inherit;
    }

    // .fc-scroller {
    //     overflow: hidden !important;
    // }

    // fc-bg-event fc-event fc-event-start fc-event-end fc-event-future mf-external-availability-event
    .fc-event-main {
        color: black;
    }
    .fc-list-event-dot {
        display: none;
    }
    .fc-non-business {
        background: transparent;
    }
    &.mf-fc-custom__availability {
        .fc-event-main-frame {
            height: auto;
        }
        .fc-timegrid-event .fc-event-main {
            padding-left: 4px;
        }
        // .fc-event-main-frame {
        //     padding: 4px 7px 0;
        // }

        // .fc-timegrid-event .fc-event-main {
        //     // padding: 4px 7px 0;
        // }
    }
    .fc-bg-event.mf-external-availability-event {
        background: repeating-linear-gradient(
                90deg,
                rgba(0, 0, 0, 0),
                rgba(0, 0, 0, 0) 5px,
                rgba(0, 0, 0, 0.2) 5px,
                rgba(0, 0, 0, 0.2) 10px
            ),
            repeating-linear-gradient(
                45deg,
                rgba(0, 0, 0, 0),
                rgba(0, 0, 0, 0) 5px,
                rgba(0, 0, 0, 0.2) 5px,
                rgba(0, 0, 0, 0.2) 10px
            );
    }

    .mf-availability-event {
        background: repeating-linear-gradient(
            45deg,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0) 5px,
            rgba(0, 0, 0, 0.2) 5px,
            rgba(0, 0, 0, 0.2) 10px
        );
    }

    .mf-customer-availability-event {
        background: repeating-linear-gradient(
            90deg,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0) 5px,
            rgba(0, 0, 0, 0.2) 5px,
            rgba(0, 0, 0, 0.2) 10px
        );
    }

    &.fc-theme-standard .fc-scrollgrid {
        border: 1px solid lighten($mf-metal, 5%);
    }
    .fc-day-sat,
    .fc-day-sun {
        background-color: lighten($mf-warning, 37%);
    }

    .fc-event-time {
        font-size: 0.85rem;
        // text-transform: uppercase;
        font-weight: 600;
    }
    .fc-timegrid-col.fc-day-today,
    .fc-daygrid-day.fc-day-today,
    .fc-day-today {
        background-color: lighten($mf-accent, 40%);
        // background-color: $mf-light-purple; //lighten($mf-light-purple, 90%);
    }

    .fc-button-primary {
        background: #f4f5f8;
        border: 0;
        color: $mf-primary;
        text-shadow: none !important;
        height: 2.75rem;
        padding: 0 1.25rem;
        font-size: 1rem;
        &:hover {
            border: 0;
            background: #e2e5ec;
            color: $mf-brand;
        }

        // &:not(:disabled):active {
        //     background-color: #c4c5d5;
        // }

        &:not(:disabled) {
            &.fc-button-active {
                background-color: $mf-brand;
                // color: $mf-primary;
            }
            &:active {
                background-color: #c4c5d5;
            }
        }

        &:disabled {
            background: #f4f5f8;
            opacity: 1;
            color: lighten($mf-primary, 40%);
        }

        // &:focus {
        //     outline: none !important;
        // }
        // &.fc-button-active {
        // }
    }
    .fc-v-event:hover,
    .fc-list-event {
        cursor: pointer;
    }
    .fc-v-event {
        border: none;
        overflow: hidden;
        // border: 1px solid $mf-metal;
    }

    .hidde-search {
        opacity: 0.3;
    }
}
