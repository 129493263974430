@import '../common.scss';
// .mCSB_container {
//     height: 100%;
// }

// .m-grid--desktop::before {
//     border: 3px solid red;
//     content: "";
// }

// .m-grid--desktop::after {
//     border: 6px solid blue;
//     content: "";
// }

// .m-grid--root::before {
//     border: 6px solid blue;
//     content: "";
// }
.m-footer--push.m-aside-left--enabled:not(.m-footer--fixed) .m-wrapper {
    margin-bottom: 0px;
}

// .mf-custom-aside-left {
//     //overflow: hidden;
// }

@media (min-width: 792px) {
    .m-page--fluid {
        .m-aside-left {
            &::before {
                z-index: 2031;
                content: '';
                height: 100%;
                width: 4px;
                /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#972cf0+0,21c5da+100 */
                background: #972cf0;
                /* Old browsers */
                background: -moz-linear-gradient(top, #972cf0 0%, #21c5da 100%);
                /* FF3.6-15 */
                background: -webkit-linear-gradient(top, #972cf0 0%, #21c5da 100%);
                /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom, #972cf0 0%, #21c5da 100%);
                /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#972cf0', endColorstr='#21c5da', GradientType=0);
                position: fixed;
                /* IE6-9 */
                // border-left: 6px solid linear-gradient(to bottom, #972cf0 0%, #21c5da 100%);
            }
        }
    }
}

@media (max-width: 792px) {
    .m-container.m-container--fluid.m-container--full-height {
        &::after {
            content: '';
            height: 4px;
            width: 100%;
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#972cf0+0,21c5da+100 */
            background: #972cf0;
            /* Old browsers */
            background: -moz-linear-gradient(left, #972cf0 0%, #21c5da 100%);
            /* FF3.6-15 */
            background: -webkit-linear-gradient(left, #972cf0 0%, #21c5da 100%);
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, #972cf0 0%, #21c5da 100%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#972cf0', endColorstr='#21c5da', GradientType=0);
            z-index: 103;
            position: fixed;
            /* IE6-9 */
            // border-left: 6px solid linear-gradient(to bottom, #972cf0 0%, #21c5da 100%);
        }
    }
}

// #m_ver_menu>ul>li.m-menu__item.m-menu__item--active>a>i {
//     color: white;
// }

// .mf-custom-aside-left {

//     // #mCSB_1_container>ul>li.m-menu__item.m-menu__item--active {

//     //     .m-menu__link-text,
//     //     a i.m-menu__link-icon {
//     //         color: white;
//     //         // font-weight: bold;
//     //     }
//     // }

// }

// .m-aside-menu.m-aside-menu--skin-dark .m-menu__nav>.m-menu__item.m-menu__item--active>.m-menu__link .m-menu__link-text {
//     color: white;
//     font-weight: bold;
// }

// .m-aside-menu.m-aside-menu--skin-dark .m-menu__nav>.m-menu__item.m-menu__item--active>.m-menu__link .m-menu__link-icon {
//     color: white;
// }
// #m_ver_menu>ul>li>a>i,
// #mCSB_1_container>ul>li>a>i,
// #mCSB_2_container>ul>li>a>i {
//     color: $mf-brand;
// }

// /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#972cf0+0,21c5da+100 */
// background: #972cf0; /* Old browsers */
// background: -moz-linear-gradient(top,  #972cf0 0%, #21c5da 100%); /* FF3.6-15 */
// background: -webkit-linear-gradient(top,  #972cf0 0%,#21c5da 100%); /* Chrome10-25,Safari5.1-6 */
// background: linear-gradient(to bottom,  #972cf0 0%,#21c5da 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#972cf0', endColorstr='#21c5da',GradientType=0 ); /* IE6-9 */

.fc-unthemed .fc-description,
.fc-unthemed .fc-event .fc-title,
.fc-unthemed .fc-event-dot .fc-title,
.fc-unthemed .fc-event .fc-time,
.fc-unthemed .fc-event-dot .fc-time {
    color: inherit;
}

.mf-ng-select-danger-border {
    &.ng-select {
        .ng-select-container {
            border-color: var(--mf-danger);
        }
    }
}
