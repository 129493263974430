/* You can add global styles to this file, and also import other style files */
@import './fonts.scss';
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import './common.scss';
@import '~@ng-select/ng-select/themes/default.theme.css';
@import '~webdatarocks/webdatarocks.min.css';
@import '~@meddev/icon-set/css/mf-font-set.css';
i[class^='mf-i-']:before,
i[class*=' mf-i-']:before {
    margin: 0;
}
@import './styles/template-override.scss';

@import './styles/calendar.scss';
@import './styles/telephony-style.scss';
@import "~@angular/cdk/overlay-prebuilt.css";

body {
    background-color: #f8f9fa;
}

.ngx-datatable.bootstrap:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
    cursor: pointer;
}

.fc-day-grid-container,
.fc-scroller .fc-time-grid-container {
    overflow-x: hidden;
}

.mce-notification,
.mce-branding {
    display: none !important;
}

.ngx-datatable.material .datatable-body .datatable-body-row .mf-action-column {
    padding-top: 6px;
    padding-bottom: 0px;
}

.ngx-datatable.material {
    box-shadow: none;
}

.mf-acc-gi-details {
    .ngx-datatable {
        .datatable-body-row.active .datatable-row-group {
            background-color: transparent !important;
        }
    }
}

.ngx-datatable {
    .datatable-body-row.active .datatable-row-group {
        background-color: $mf-light-purple !important;
    }
}

.modal-lg {
    max-width: 90%;
}

.modal-md {
  max-width: 60%;
}

#cdk-overlay-0,
.owl-dt-container,
.cdk-overlay-container {
    z-index: 99999 !important;
}

.m-portlet__body {
    .btn {
        white-space: normal;
    }
}

.fc-sun,
.fc-sat {
    background-color: #f7f4fa;
    border-color: black;
}

//odstranimo padding zaradi pike
.fc-unthemed .fc-event .fc-content {
    padding-left: 0.55rem;
}

//odstranimo piko
.fc-unthemed .fc-event .fc-content:before,
.fc-unthemed .fc-list-item .fc-event-dot,
.fc-unthemed .fc-event-dot .fc-content:before {
    display: none;
}

.mf-settings-availability-calendar .mf-customer-availability-event {
    background: repeating-linear-gradient(
        90deg,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0) 5px,
        rgba(0, 0, 0, 0.2) 5px,
        rgba(0, 0, 0, 0.2) 10px
    );
}

.mf-settings-availability-calendar .mf-external-availability-event {
    background: repeating-linear-gradient(
            90deg,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0) 5px,
            rgba(0, 0, 0, 0.2) 5px,
            rgba(0, 0, 0, 0.2) 10px
        ),
        repeating-linear-gradient(
            45deg,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0) 5px,
            rgba(0, 0, 0, 0.2) 5px,
            rgba(0, 0, 0, 0.2) 10px
        );
}

.mf-settings-availability-calendar .mf-availability-event {
    background: repeating-linear-gradient(
        45deg,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0) 5px,
        rgba(0, 0, 0, 0.1) 5px,
        rgba(0, 0, 0, 0.1) 10px
    );
}

//-konec BARVE ZA PODIZVAJALCA
.mf-content {
    padding: 10px;
    background-color: white;
}

.mf-popup-title {
    color: white;
    text-align: center;
}

.m-portlet {
    margin-bottom: 15px;
    .mf-popup-title {
        color: white;
        text-align: center;
    }

    border-radius: 5px;

    .m-portlet__head {
        border-radius: 5px 5px 0 0;
        height: 3rem;
        padding: 0 1rem;

        .m-portlet__head-caption {
            .m-portlet__head-title {
                .m-portlet__head-text {
                    font-size: 1rem;
                }
            }
        }
    }

    .m-portlet__body,
    .m-portlet__foot {
        padding: 1rem;
    }
}

.m-tabs-line a.m-tabs__link.active,
.m-tabs-line a.m-tabs__link:hover,
.m-tabs-line.nav.nav-tabs .nav-link.active,
.m-tabs-line.nav.nav-tabs .nav-link:hover {
    border-bottom: 1px solid $mf-brand;
}

.ngx-datatable.material.mf-custom-pagination .datatable-footer {
    border: none;
    border-top: none;
}

.ngx-datatable.material.striped .datatable-row-odd {
    background-color: #faf9fc;
}

.page-item.active .page-link {
    background-color: #21c5da;
    border-color: #21c5da;
}

.fc-today {
    background: rgb(237, 253, 255) !important;
}

.m-portlet.mf-portlet-gray,
.m-portlet.mf-portlet-purple {
    .m-portlet__head {
        background-color: $mf-metal;

        .m-portlet__head-caption .m-portlet__head-title .m-portlet__head-text {
            font-weight: normal;
        }

        .m-portlet__head-text,
        .m-portlet__head-tools .m-portlet__nav .m-portlet__nav-item .m-portlet__nav-link.m-portlet__nav-link--icon i {
            color: white;
        }

        .m-portlet__head-tools {
            .m-tabs__item {
                height: 41px;

                a.m-tabs__link {
                    padding: 7px 0 0 0;
                    color: white;
                }
            }
        }
    }
}

.m-portlet.mf-portlet-purple {
    .m-portlet__head {
        background-color: $mf-light-purple;
    }
}

.mf-transparent-popup {
    .modal-content {
        background-color: transparent; // border: 1px solid white;
        box-shadow: none;
    }

    .modal-content {
        .modal-footer {
            padding: 0px;
            border: none;
        }
    }
}

.mf-gray-background-popup {
    .m-portlet.mf-portlet-gray__gray-background {
        background-color: $mf-light-gray-hover;
    }
}

.mf-popup-with__title {
    .modal-dialog {
        margin-top: 5px;
    }
}

.mf-table {
    .mf-table-header {
        border-bottom: 1px dashed $mf-metal;
    }

    .mf-table-body__item {
        display: table;
        width: 100%;
        padding: 10px 5px;
    }

    .mf-table__caption {
        font-weight: bold;
        display: table-cell;
    }

    .mf-table__text {
        display: table-cell;
    }

    .mf-table-body {
        .mf-table-body__item:not(:first-child) {
            border-top: 1px dashed $mf-metal;
        }
    }

    .mf-column-1 {
        width: 80%;
    }

    .mf-column-2 {
        width: 10%;
    }

    .mf-column-3 {
        width: 10%;
    }

    .m-checkbox {
        padding: 0px;
        margin-bottom: 15px;
    }
}

.modal-backdrop.mf-second-modal-backdrop {
    z-index: 1050;
}

.mf-dashed-h {
    border-bottom: 1px dashed $mf-metal;
}

.mf-dashed-h__light {
    border-bottom: 1px dashed #ebedf2;
}

.mf-services-settings-table {
    padding: 10px;
    width: 100%;

    thead {
        tr {
            height: 40px;
        }

        td,
        i {
            font-weight: bold;
        }
    }

    tbody > tr {
        border-top: 1px dashed #ebedf2;
    }

    .mf-duration {
        text-align: center;
        width: 20%;
    }

    .mf-value {
        text-align: center;
        width: 25%;
    }

    .mf-subc {
        width: 55%;
    }

    td > .m-checkbox.m-checkbox--single,
    th > .m-checkbox.m-checkbox--single {
        margin-top: 12px;
    }
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border-color: $mf-brand;
    box-shadow: none;
}

.ng-select .ng-select-container {
    border: 1px solid #ebedf2;
    font-size: 0.875rem;
    line-height: 1.5;
}

.ng-select-disabled .ng-arrow-wrapper {
    display: none;
}

.mf-custom-type-ahead {
    .dropdown-menu {
        max-height: 300px;
        max-width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.swal2-icon.swal2-warning,
.swal2-icon.swal2-info {
    font-size: 20px;
}

.mf-recurring-popup {
    padding-left: 40px;

    label {
        text-align: left;
    }
}

.ngx-datatable.material .datatable-body .datatable-row-detail {
    padding-right: 25px;
}

.ngx-datatable .datatable-header .datatable-header-cell .resize-handle, .ngx-datatable .datatable-header .datatable-header-cell .resize-handle {
    visibility: visible !important; 
    height: 20px;
    margin-top: 5px;

    &:hover {
        border-color: $mf-brand;
    }
}

.mf-text-wrap {
    word-break: break-word;
}

@media print {
    .m-header__top,
    .mf-filter-print {
        display: none;
    }
}

.mf-date-picker {
    border: none;

    .ngb-dp-weekdays {
        .ngb-dp-weekday {
            font-style: normal;
            font-weight: bold;
            color: $mf-primary;
        }
    }
}

.mf-accounting-reports {
    border-left: 1px dashed $mf-metal;
}

.flex-even {
    flex: 1;
}

button:disabled {
    cursor: not-allowed;

    &.cursor-wait {
        cursor:wait;
    }
}

#priceInputTriage {
    border-radius: 10px;
}

#notificationMarkAsRead {
    cursor: pointer;
    padding-left: 10px;
}

th.fc-resource-cell {
    overflow: hidden;
}

.m-aside-menu.m-aside-menu--skin-dark .m-menu__nav,
.m-aside-left--minimize .m-aside-menu.m-aside-menu--skin-dark .m-menu__nav {
    > .m-menu__item,
    .m-menu__submenu .m-menu__item {
        margin: 0 1rem !important;

        &.m-menu__item--active {
            background: #ffffff2b !important;
            border-radius: 0.25rem !important;

            > .m-menu__link {
                .m-menu__link-icon,
                .m-menu__link-text,
                .m-menu__ver-arrow {
                    color: #ffffff !important;
                }

                .m-menu__link-text {
                    font-weight: 700 !important;
                }

                .m-menu__link-bullet.m-menu__link-bullet--dot > span {
                    background-color: #ffffff !important;
                }
            }
        }

        &.m-menu__item--open,
        &.m-menu__item--hover {
            > .m-menu__link {
                .m-menu__link-icon,
                .m-menu__link-text,
                .m-menu__ver-arrow {
                    color: #ffffff !important;
                }
            }
        }
    }

    i {
        color: #cbcbcb !important;
    }
}

.m-aside-left--minimize .m-aside-menu .m-menu__nav > .m-menu__item {
    &.m-menu__item--open,
    &.m-menu__item--hover {
        width: 52px;

        > .m-menu__link {
            width: 52px;
        }
    }
}

.m-aside-menu.m-aside-menu--skin-dark .m-menu__nav > .m-menu__item > {
    .m-menu__submenu .m-menu__item > .m-menu__link .m-menu__link-text {
        color: #cbcbcb;
    }

    .m-menu__link {
        padding: 9px 20px;

        .m-menu__link-icon,
        .m-menu__link-text {
            color: #cbcbcb;
        }
    }
}

.m-aside-left--minimize .m-aside-left {
    .m-aside-menu .m-menu__nav > .m-menu__item > .m-menu__link {
        @media (min-width: 792px) {
            padding-left: 0;
            padding-right: 0;
            text-align: center;
        }
    }
}

.m-aside-left.m-aside-left--skin-dark,
.m-brand.m-brand--skin-dark {
    background: #212529;
}

@media (max-width: 792px) {
    .m-header--fixed-mobile.m-topbar--on .m-body {
        padding-top: 90px;
    }

    .m-aside-left--minimize .m-aside-menu .m-menu__nav > .m-menu__item,
    .m-aside-menu .m-menu__nav > .m-menu__item {
        &.m-menu__item--open,
        &.m-menu__item--hover {
            width: 229px;

            > .m-menu__link {
                width: 229px;
            }
        }
    }
}

@media (min-width: 792px) {
    .m-stack.m-stack--desktop {
        display: table;
    }

    .m-header--fixed .m-body {
        padding-top: 0 !important;
    }

    .m-header--fixed.m-header--on .m-body {
        padding-top: 50px !important;
    }

    .m-header {
        display: table;
    }

    .m-header--fixed {
        .m-header {
            -webkit-transition: none;
            -moz-transition: none;
            -ms-transition: none;
            -o-transition: none;
            transition: none;
            padding-left: 255px;
        }
    }

    .m-header--fixed.m-aside-left--minimize {
        .m-header {
            -webkit-transition: none;
            -moz-transition: none;
            -ms-transition: none;
            -o-transition: none;
            transition: none;
            padding-left: 80px;
        }
    }
}

@media (min-width: 792px) {
    .m-page--fluid .m-header {
        display: none !important;
    }
}

.m-page--fluid.m-header--on .m-header {
    background-color: #ffffff;
    display: table-cell !important;
}

.mf-cursor-pointer:hover {
    cursor: pointer;
}

.mf-darken-hover:hover {
    filter: brightness(110%);
}
.m-quick-sidebar {
    z-index: 1003;
}

.m-topbar {
    float: left;

    @media (min-width: 792px) {
        .mf-header-submenu {
            padding-left: 0;
        }
    }
}

.mf-header--sub-title {
    padding-right: 1rem;
    margin-right: 0.5rem;
    border-right: 1px solid $mf-metal;
    font-size: 16px;
    font-weight: 600;
    color: #44146e;
}

.m-aside-menu .m-menu__nav > .m-menu__section .m-menu__section-text {
    font-weight: 600;
}

.m-aside-left--minimize
    .m-aside-menu.m-aside-menu--submenu-skin-dark
    .m-menu__nav
    .m-menu__item:not([data-menu-submenu-mode='accordion']).m-menu__item--hover
    > .m-menu__submenu
    .m-menu__subnav
    .m-menu__item
    > .m-menu__link
    .m-menu__link-text {
    color: #cbcbcb;
}
.m-aside-left--minimize {
    .mf-sub-icon {
        display: inline !important;
        padding: 5px;
    }
}

.m-aside-menu .m-menu__nav > .m-menu__item > .m-menu__submenu .m-menu__subnav > .m-menu__item > .m-menu__link {
    padding: 5px 40px 5px 45px;
}

.m-menu__subnav {
    padding-bottom: 9px !important;
}

@media (max-height: 780px) {
    .m-aside-left--minimize
        .m-aside-menu
        .m-menu__nav
        .m-menu__item:not([data-menu-submenu-mode='accordion'])
        > .m-menu__submenu,
    .m-aside-menu.m-aside-menu--dropdown
        .m-menu__nav
        .m-menu__item:not([data-menu-submenu-mode='accordion'])
        > .m-menu__submenu {
        position: absolute !important;
        bottom: -20px !important;
        top: unset !important;
    }

    .m-aside-left--minimize
        .m-aside-menu
        .m-menu__nav.m-menu__nav--dropdown-submenu-arrow
        .m-menu__item.m-menu__item--hover
        > .m-menu__submenu
        > .m-menu__arrow,
    .m-aside-menu.m-aside-menu--dropdown
        .m-menu__nav.m-menu__nav--dropdown-submenu-arrow
        .m-menu__item.m-menu__item--hover
        > .m-menu__submenu
        > .m-menu__arrow {
        top: unset !important;
        bottom: 20px;
    }
}

.m-portlet.m-portlet--tabs .m-portlet__head .m-portlet__head-tools .m-tabs-line {
    .m-tabs__item {
        margin-right: 20px;
    }

    .m-tabs__link {
        padding: 12px 0;
        border-width: 2px;
    }
}

.swal2-popup .swal2-content {
    font-size: 1.2rem;
}

.mf-h-input {
    position: absolute;
    left: -2000px;
}

.la {
    @for $i from 2 through 5 {
        &.la-#{$i}x {
            font-size: #{$i}em !important;
        }
    }
}

/* Intercom custom button color */
.intercom-launcher-frame {
    background: linear-gradient(90deg, #972cf0 0%, rgba(151, 44, 240, 0.7) 100%) !important;
}

.file-upload {
    outline-offset: 0 !important;
    padding: 1rem !important;
    outline: #ebedf2 dashed 1px !important;

    &.list-visible {
        .upload-input {
            position: relative;
            top: 1rem !important;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;
            margin-left: 0 !important;
        }
    }

    .file-info {
        border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
        padding: 5px 0 5px 0;

        &:last-child {
            border: none;
            padding-bottom: 0px;
        }

        a {
            cursor: pointer;
        }
    }
}

.fc-license-message {
    display: none;
}

.daterangepicker .ranges li.active {
    background-color: $mf-brand !important;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
    outline: 0px !important;
    box-shadow: none !important;
}

.mf-form-submited {
    input.ng-invalid,
    ng-select.ng-invalid {
        border: 1px solid $mf-danger;
    }
}

.mf-swal-content {
    #swal2-content {
        text-align: justify;
    }
}

.m-portlet .m-portlet__head .m-portlet__head-caption .m-portlet__head-title .m-portlet__head-text {
    font-weight: 700;
}
.oim-modal {
    visibility: hidden !important;
}
.oim-modal:last-of-type {
    visibility: visible !important;
}

.ng-dropdown-panel {
    width: auto !important;
    max-width: 550px;
    min-width: 100%;
}

.progress-linear {
    position: relative !important;

    > .container {
        background-color: var(--mf-light-purple) !important;
        max-width: 100% !important;

        >.bar {
            background-color: var(--mf-brand) !important;
        }
    }
}

.text-nowrap-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
}

.overflow-hidden {
    overflow: hidden;
}

// PDF-js component
.ng2-pdf-viewer-container {
    overflow: hidden;
    overflow-x: hidden !important;
    height: auto !important;
}

