$mf-light-gray-hover: #f7f8fa;
$mf-light-purple: #dad8ee;
$mf-brand: #972cf0;
$mf-accent: #21c5da;
$mf-metal: #c4c5d5;
$mf-success: #3dbea3;
$mf-warning: #fdb737;
$mf-danger: #fb5058;
$mf-primary: #5f6066;
$mf-asistent-color: #fdb737;
// $x:#575962

:root {
    --mf-brand: #972cf0;
    --mf-danger: #fb5058;
    --mf-light-purple: #dad8ee;
    --mf-primary: #5f6066;
    --mf-metal: #c4c5d5;
    --mf-accent: #21C5DA;
}
